<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">calendar</div>
      </v-row>
      <v-row class="">
        <div class="">
          Meeting agendas are generally linked to the calendar 24 hours before
          the meeting. The marked agenda shows the actions taken on each item
          after the meeting
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <v-row class="justify-center text-capitalize" dense>
        <v-col v-for="item in menuItems" :key="item.title">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-caption">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-col>
      </v-row>

      <v-tabs
        :fixed-tabs="!isMobile"
        :vertical="isMobile"
        background-color="transparent"
        class="black--text"
        active-class="green--text"
        color="green"
      >
        <v-tab>house</v-tab>
        <v-tab>commitee</v-tab>
        <v-tab>boards and commisions</v-tab>
        <v-tab>events</v-tab>
        <v-tab>all calendar</v-tab>
      </v-tabs>
      <v-card-title>
        <v-text-field
          append-icon="mdi-magnify"
          clearable
          outlined
          label="Search by name"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn class="mr-2 mb-7" color="primary"
          >subscribe <v-icon right>mdi-mail</v-icon></v-btn
        >
        <v-btn class="mb-7" color="primary"
          >print <v-icon right>mdi-printer</v-icon></v-btn
        >
      </v-card-title>

      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn class="mr-4" color="primary" @click="setToday">
                Today
              </v-btn>
              <v-btn fab text small color="primary" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="primary" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-dialog
              v-model="selectedOpen"
              :activator="selectedElement"
              width="500"
            >
              <v-card color="grey lighten-4" width="500" flat class="mx-auto">
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-toolbar-title
                    class="text-title"
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-calendar-star</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <span v-html="selectedEvent.details"></span>
                  <p class="text-title black--text">Event data will go here</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" @click="selectedOpen = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menuItems: [
      {
        title: "public hearing",
        icon: "mdi-account-group",
      },
      {
        title: "updated session",
        icon: "mdi-update",
      },
      {
        title: "adjourned session",
        icon: "mdi-timer-cog-outline",
      },
      {
        title: "special seating",
        icon: "mdi-calendar-star",
      },
      {
        title: "adhock session",
        icon: "mdi-calendar-alert",
      },
      {
        title: "cancelled session",
        icon: "mdi-cancel",
      },
    ],
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Public Hearing",
      "Commitee",
      "Budget",
      "Pass Bill",
      "Presidential Address",
      "National Holiday",
      "Conference",
      "Education Council",
    ],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
  },
};
</script>

<style></style>
